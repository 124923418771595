
$button-color: #b64e29; // Adjust to your preferred button color

.wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    overflow-y: scroll;
    padding-bottom: 10px;

    .visited-container {
        width: 100%;
        display: flex;
        justify-content: center; /* Center the button horizontally */
        align-items: center; /* Center the button vertically */
        padding-bottom: 7px;
        background-color: rgb(55, 55, 55);
        border-radius: 0 0 5px 5px;
    }
      
      .visited-button {

            display: inline-block;
            padding: 8px;
            background-color: $button-color;
            color: #fff;
            cursor: pointer;
            transition: background-color 0.3s;
            width: 150px;
            text-align: center;
            border-radius: 5px;
          }
  
          p:hover {
            background-color: darken($button-color, 10%);
          }
}