.outterContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(73, 73, 73, 0.719);
    z-index: 49;
}
.innerContainer {
    padding: 15px;
    position: relative;
    background-color: white;
    z-index: 50;

    button {
        cursor: pointer;
    }
}

.notesBox {
    font-size: 12px;
    padding: 5px;
    flex: 1;
    margin-right: 20px;
}

.partImg {
    max-height: 110px;
    border-radius: 5px;
}

.title{
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 16px;
    margin: 5px;
}
.buttonsContainer {
    display: flex;
    justify-content: center;
    margin: 10px;
}
 

 .cancelButton {
    font-size: 16px;
    width: 100px;
    height: 40px;
    margin: 10px;
    color: rgb(15, 15, 15);
}

.submitButton {
    font-size: 16px;
    margin: 10px;
    width: 100px;
    height: 40px;
    color: rgb(15, 15, 15);
}