.header {
    width: 100%;
    position: fixed;
    top: 0;
    background: linear-gradient(to top, rgb(62, 62, 71), rgb(28, 29, 31));
    height: 65px;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.50);
    z-index: 20;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid rgb(119, 119, 119);
    color: rgb(235, 232, 232);

    h2 {
        margin-left: 10px;
        margin-bottom: -10px;
    }

    img {
        height: 50px;
        margin-right: 20px;
    }

    i {
        font-size: 48px;
        color: rgb(235, 232, 232);
        margin-left: 40px;
        cursor: pointer;
    }

}

@media(max-width: 500px) {
    .header {
        img {
            margin-right: 20px;
        }

        i {
            margin-left: 15px;
        }
    }
}