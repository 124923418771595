.outterWrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    overflow-y: hidden;

    .arrow {
        color: #b64e29;
        padding: 10px;
        font-size: 40px;
    }

    .container {
        display: flex;
        align-items: center;
        width: 350px;

        
        .innerWrap {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 100%;
            
            img {
                width: 100%;
            }
            h3 {
                z-index: 1;
                color: white;
                background-color: rgba(22, 22, 22, 0.75);
                margin: 0;
                text-align: center;
            }
        }
    }
}