.wrapper {
    max-width: calc(100vw - 10px);
    .loading{
        width: 150px;
        padding: 20px;
    }
    .showDay1 {
        width: 100%;
        text-align: right;
        
    }
    span{
        cursor: pointer;
    }
}