.wrapper {
    display: flex;
    background-color: rgb(226, 223, 223);
    width: 100%;
    padding: 5px;

    p {
        font-size: 20px;
    }

    &:nth-child(odd){
        background-color: rgb(255, 253, 253);
    }

    i:hover {
        color: #b64e29
    }

    i {
        margin: 0 3px 0 3px;
        cursor: pointer;
    }
}