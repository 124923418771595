.w3-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2px;

    .arrow {
        color: #b64e29;
        padding: 4px;
        font-size: 25px;
    }
    p {
        color: white;
    }
    h2 {
        color: white;
    }

    .partWrapper {
        padding: 5px 0px 5px 5px;
        background-color: rgb(55, 55, 55);
    }
}