.quickSearchWrapper {
  padding: 20px;
  height: 100%;
}

.newPartButton,
.newSearchButton {
  position: absolute;
  top: 80px; /* Distance from the top */
  right: 20px; /* Distance from the right */
  background-color: #b64e29; /* Button background color */
  color: white; /* Button text color */
  border: none;
  padding: 8px 15px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px; /* Optional: Makes button edges rounded */
  z-index: 2; /* Ensure button is on top of the table */
}

.newPartButton:hover,
.newSearchButton:hover {
  background-color: #9b3814; /* Darker color on hover */
}

.newPartButton {
  left: 20px;
  right: auto;
}
.tableWrapper {
  margin-top: 20px;
  height: 85vh; /* Set the height of the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ddd; /* Optional: Add border around the scrollable area */
}

.scrollableTable {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.scrollableTable th,
.scrollableTable td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.scrollableTable th {
  background-color: #f1f1f1;
  position: sticky;
  top: 0; /* Keeps headers at the top */
  z-index: 2; /* Ensures headers stay above other elements */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.scrollableTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.scrollableTable tr:hover {
  background-color: #f1f1f1;
}

.carLinkWrapper {
  display: flex;
  align-items: center; // Ensure icons are aligned with the text
  position: relative; // Set position relative for child elements
}

svg {
  cursor: pointer;
  margin: 0 5px;
  opacity: 0; // Hide icons initially
  transition: opacity 0.2s ease; // Smooth transition when showing/hiding

  &:hover {
    color: #b64e29;
  }
}

a {
  color: inherit;
  text-decoration: none;

  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }
}

// Show icons only on hover
tr:hover .carLinkWrapper svg,
tr:hover .carLinkWrapper a {
  opacity: 1;
}
