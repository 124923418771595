$button-color: #b64e29; // Adjust to your preferred button color
.wrapper {
  display: flex;
  width: 100%;
  justify-content: center;

  .componentsWrapper {
    display: block;
    max-width: 500px;
    width: calc(100vw - 5px);
    background-color: rgb(55, 55, 55);
    border-radius: 5px;

    .yardControls {
      position: relative;
      margin: 5px;
      padding: 3px;
      .yardSelect {
        display: flex;
        justify-content: center;

        select {
          appearance: none;
          width: 80%;
          text-align-last: center;
          direction: ltr;
          font-size: 20px;
          margin: 5px;
          background-color: rgb(55, 55, 55);
          color: white;
          padding: 8px;
          border: none;
          outline: none;
        
        }

        .search {
          cursor: pointer;
          position: absolute;
          right: 0;
          margin: 8px 5px;
          color: white;
        }
        .eye{
          cursor: pointer;
          position: absolute;
          left: 0;
          margin: 8px 5px;
          color: white;
        }
      }

      .optionsToggle {
        display: flex;
        justify-content: space-around;
        padding: 5px;

        p {
          display: inline-block;
          padding: 8px;
          background-color: $button-color;
          color: #fff;
          cursor: pointer;
          transition: background-color 0.3s;
          width: 150px;
          text-align: center;
          border-radius: 5px;
        }

        p:hover {
          background-color: darken($button-color, 10%);
        }
      }
    }
  }
}
