.wrapper {
    padding: 10px;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;

    .loading {
        width: 100%;
        padding: 20px;
    }
}