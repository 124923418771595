.wrapper {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.50);
    background-color: rgb(55, 55, 55);
    .partInfoDisplay {
        img {
            height: 50px;

        }
        .tableImageContainer{
            display: flex;
        }
    }

    .vicInfoWrapper{
        display: flex;
        padding: 2px 20px;
        align-items: center;

        .daysIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20%;
            color: rgb(226, 226, 226);
            font-size: 22px;
            width: 26px;
            height: 26px;
            margin-right: 5px;
        }
        .icon {
            height: 20px;
            margin: 2px;
        }
       
        .detail {
            display: flex;
            margin: 2px 6px;
            p {
                color: rgb(236, 234, 234);
            }
            img {
                height: 20px;
                margin-right: 5px;
            }
        }

        .carMoneyWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: rgb(231, 231, 231);
            margin-left: 10px;
            width: 40px;
            span {
                font-size: 10px;
                color: rgb(231, 231, 231);
            }
        }
    }

    margin: 5px;
    padding: 3px 6px;

    h4 {
        color: orange;
    }

    h3 {
        cursor: pointer;
        color: rgb(245, 244, 244);
    }


}

.oneDay {
    background-color: rgb(4, 163, 4) !important;
}

.twoDay {
    background-color: rgb(2, 187, 201) !important;
}

.threeFiveDay {
    background-color: rgb(32, 102, 253) !important;
}

.sixSevenDay {
    background-color: rgb(76, 1, 197) !important;
}