.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: calc(100vw - 5px);
    border-radius: 14px 14px 0 0;
    z-index: 19;
    margin: 20px 8px 0 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 2px 0 0px -4px rgba(31, 31, 31, 0.8);
    flex: 1;
    

    header {
        width: 100%;
        // border-radius: 0px 0px 0 0;
        margin-top: -2px;
        background: linear-gradient(to top, rgb(80, 80, 90), rgb(48, 50, 53));
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.50);
        margin-bottom: 4px;
        padding-bottom: 5px;

        & > div {
            display: flex;
            justify-content: space-around;
            align-items: center;

            label {
                display: flex;
                margin-right: 15px;
                align-items: center;
                color: rgb(226, 226, 226);
                cursor: pointer;
            }
        }

        p {
            color: rgb(226, 226, 226);
            padding-top: 0;
            margin: -6px 5px 0 8px;
            font-size: 16px;
            text-align: center;
        }

        h3 {
            font-size: 23px;
            color: rgb(216, 215, 215);
            margin: 0 4px 0 0;
        }
    }

    p {
        font-size: 20px;
        color: rgb(49, 49, 49);
        margin: 5px;
    }
}

.listOfCars {
    flex: 1;
    min-height: 200px;
    width: 100%;
    overflow-y: scroll;
    padding: 5px 0px 10px 1px;

    .loadingWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .loadingImg {
            width: 150px;
        }
    }

    

    &::-webkit-scrollbar {
        background-color: rgb(245, 245, 245);
        width: 6px;
        z-index: 1;
        padding-top: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgb(138, 136, 136);

    }
}

.listItemWrapper {
    display: grid;
    padding: 0 15px 0 5px;
    grid-template-columns: 40px 1fr 30px;
    align-items: center;
    background: rgb(252, 252, 252);

    h2 {
        cursor: pointer;
        color: rgb(53, 51, 46);
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: normal;
        white-space: nowrap;
        text-decoration: none;
    }

    p {
        width: 100%;
        text-align: right;
        padding-right: 5px;
        color: #b64e29;
    }

    img {
        width: 30px;
    }

    &:nth-child(even) {
        background: rgb(224, 224, 224);
    }
}

.sort {
    width: 100%;
    background: rgb(243, 243, 243);
    height: 40px;

    p {
        color: rgb(50, 50, 50);
        margin: 10px;
    }

    div {
        p {
            cursor: pointer;
            font-size: 24px;
        }
    }
}

.dropdown {
    min-width: 65%;
    width: fit-content;
    margin: 10px 0 10px 0;
    padding: 5px;
}

@media(max-width: 500px){
    .wrapper{
        height: calc(100vh - 95px);
        max-width: calc(100vw - 10px);

        label {
            font-size: 12px;
        }
        h2 {
            font-size: 12px;
        }

        .sort {
            p {
                font-size: 14px;
            }
        }
    }
    
    
}