$textureBackground: white;

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 90px);
    width: 100vw;
    background-color: $textureBackground;

    form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        max-width: 350px;
        min-width: 300px;
        background: rgb(255, 255, 255);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
        padding: 20px;
        border-radius: 10px;

        p {
            color: rgb(243, 96, 96);
            font-size: 14px;
            text-align: center;
        }
    }
}

.signInBox {
    width: fit-content;
    background: linear-gradient(to top, rgb(60, 60, 70), rgb(50, 50, 60));
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 30px;
    gap: 15px;

    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;

        img {
            height: 60px;
        }

        h1 {
            color: lightgray;
            font-size: 24px;
            font-weight: 600;
        }
    }
}

.inputBox {
    width: 100%;
    margin: 10px 0;
    font-size: 16px;
    padding: 10px;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 5px;
    text-align: left;
    background: rgb(245, 245, 245);

    &:focus {
        border-color: rgb(90, 90, 100);
        outline: none;
        box-shadow: 0 0 5px rgba(90, 90, 100, 0.5);
    }
}

.signInButton {
    width: 100%;
    font-size: 18px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: rgb(90, 90, 100);
    color: white;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: rgb(73, 74, 77);
    }

    &:active {
        background: rgb(60, 60, 70);
    }
}
