.savePartModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: rgb(55, 55, 55);
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .imageInput,
  .inputField {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s ease;
  
    &:focus {
      border-color: #b64e29;
    }
  }
  
  .numberInputs {
    display: flex;
    gap: 10px;
  
    .inputField {
      width: calc(50% - 5px); // Adjust width to fit two inputs with a gap
    }
  }
  
  .imagePreview {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  
    img {
      max-width: 100%;
      max-height: 200px;
      border-radius: 4px;
      border: 1px solid #ccc;
    }
  }

  .buttonWrapper{
    display: flex;
    gap: 10px;
  }
  
  .nextButton {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #b64e29;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #923e20;
    }
  
    &:disabled {
      background-color: #cccccc;
      cursor: not-allowed;
    }
  }
  