.wrapper {
    position: relative;
    z-index: 8;
    padding-top: 20px;
    flex: 8;
    padding: 10px;
    overflow-y: scroll;
.partInfoWrapper{
    select{
        height: fit-content;
        font-size: 14px;
        padding: 2px;
        margin-right: 20px;
    }
    display: flex;
    align-items: center;
}
    .parts {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: flex-start;
        padding-left: 80px;
        padding-right: 80px;

    }

    &::-webkit-scrollbar {
        background-color: rgb(224, 223, 223);
        width: 8px;
        z-index: 1;
        padding-top: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(138, 136, 136);

    }

    h5 {
        position: absolute;
        top: calc(50% - 50px);
        font-size: 30px;
        color: rgb(121, 120, 120);
        border: 3px dashed rgb(121, 120, 120);
        padding: 30px;
        border-radius: 10px;
    }

    h4 {
        font-size: 30px;
        color: rgb(121, 120, 120);
        padding: 20px;
        border-radius: 10px;
    }
}