.innerWrap {
    position: relative;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.50);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    background-color: rgb(55, 55, 55);
    width:340px;
    height: fit-content;
    padding: 6px;
    border-radius: 10px;

    .locationNoteWrapper {
        input {
            width: 100%;
        }
    }
    .confirmDelete {
        display: flex;
        flex-direction: column;
        padding-left: 3px;
    }

    .addCarWrapper {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        i {
            color: white;
            cursor: pointer;
            margin-right: 2px;

        }
        img:hover{
            opacity: 1;
            cursor: pointer;
        }
        img {
            width: 25px;
            margin-left: 12px;
            opacity: 0.9;
        }
        select {
            font-size: 16px;
            border-radius: 5px;
            color: white;
            background-color: rgb(55, 55, 55);
            padding: 2px;
            max-width: 180px;
        }
    }

    .addCarInputs {
        display: flex;
        align-items: center;

        .dropdown{
            width: 80px;
            margin: 2px;
            font-size: 13px;
            color: white;
            background-color: rgb(55, 55, 55);
            padding: 2px;
            height: 25px;
            border-radius: 5px;

        }
        .yearDropdown{
            width: 35px;
            margin: 2px;
            font-size: 13px;
            color: white;
            background-color: rgb(55, 55, 55);
            padding: 2px;
            -webkit-appearance: none;
            text-align: center;
            height: 25px;
            border-radius: 5px;


        }
        img {
            width: 24px;
            height: 24px;
            margin: 5px;
        }
        .addOrDeleteImg:hover {
            opacity: 1;
            cursor: pointer;
        }
        .addOrDeleteImg {
            opacity: 0.9;
        }
        span{ 
            font-size: 12px;
            color: rgb(241, 240, 240);
        }
        input {
            font-size: 12px;
            border: 0;
            outline: 0;
            border-bottom: 1px solid white;
        }
    }
    .compSearch{
        position: absolute;
        top: 8px;
        left: 8px;
        color: white;
    }
    .locationSearch {
        position: absolute;
        top: 8px;
        right: 8px;
        color: white;
    }
    input {
        font-size: 16px;
        color: rgb(247, 247, 247);
        background-color: rgb(55, 55, 55);
        text-align: center;
        border: none;
        margin: 2px;
        width: 80%;

    }
    .year {
        width: 38px;
    }

    .partNumbers {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        input {
            font-size: 16px;
            width: 42px;
        }
    }

    .partsImage {
        height: 35px;
    }

    .partsImage2 {
        height: 30px;
    }



    h4 {
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        color: #b64e29;
        font-size: 14px;
        padding: 5px;
        font-weight: 200;
    }

    p:hover {
        background-color: #a05337;
        cursor: pointer;
    }

    p {
        width: 150px;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        border-radius: 5px;
        color: rgb(243, 243, 243);
        text-align: center;
        background-color: #b64e29;
        font-size: 14px;
        padding: 8px 8px;
        margin-top: 5px;
    }
    .cylinderWrapper:hover{
        cursor: pointer;
    }
    .cylinderWrapper {
        display: flex;

        .cylinderIcon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20%;
            color: rgb(241, 240, 240);
            font-size: 16px;
            width: 28px;
            height: 28px;
        }

        
    }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

}