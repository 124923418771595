.wrapper {
    padding: 5px;
    background-color: rgb(55, 55, 55);
    width: 180px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.50);


    a {
        width: 100%;
        height: 100%;
    }

    & * {
        text-decoration: none !important;
        color: black;
    }

    p {
        color: rgb(226, 225, 225);
    }

    .carsInfoWrapper{
        margin: 3px;
        display: flex;
        
        span {
            color: rgb(226, 225, 225);
            font-size: 13px;
        }
        
    }
    .partsInfoWrapper{
        margin: 3px;
        display: flex;
        
        span {
            color: rgb(226, 225, 225);
            font-size: 13px;
        }
        
    }

    h2:hover {
        color: #b64e29;
        
    }

    h2 {
        color: rgb(226, 226, 226);
        width: 100%;
        text-align: center;
    }

    margin: 5px;
}

.daysIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    color: rgb(226, 226, 226);
    font-size: 20px;
    width: 26px;
    height: 26px;
    margin-left: 5px;
}

.oneDay {
    background-color: rgb(4, 163, 4) !important;
}

.twoDay {
    background-color: rgb(2, 187, 201) !important;
}
.hotList {
    background-color: #b64e29 !important;
    width: 35px;
}