.wrapper {
    margin-right: 40px;

    * {
        margin: 0 20px 0 20px;
    }

    a {
        text-decoration: none;
        font-size: 28px;
        color: rgb(235, 232, 232);
    }
}

@media(max-width: 500px) {
    .wrapper {
        a {
            display: none;
        }
    }

}