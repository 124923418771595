/* Updated color palette and styling for a refined look */
$seller-bg: #4E515A; // A sleek dark gray background
$seller-border: #ccc;
$primary-color: #d36d38; // A warmer, brighter primary tone
$secondary-color: #b2542a; // A complementary secondary tone
$text-color: #080808; // Light text for contrast on a dark background
$font-family: "Lato", sans-serif;
$header-bg: #ffffff;
$box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

.sellerDisplayWrapper {
  font-family: $font-family;
  background: $seller-bg;
  border: 1px solid $seller-border;
  border-radius: 12px;
  padding: 24px;
  box-shadow: $box-shadow;
  width: 350px;
  margin: 15px auto;
  overflow: hidden;
  transition: max-height 1s ease-out;

  /* When not expanded, max-height could be set to 0; 
     here we assume it is toggled with an additional .show class */
  &.show {
    visibility: visible;
    max-height: 1000px; // Large enough value to show content
  }
  &:not(.show) {
    visibility: hidden;
    max-height: 0;
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    background: $header-bg;
    padding: 12px;
    border-radius: 8px;

    .logo {
      width: 70px;
      height: 70px;
      margin-right: 16px;
      object-fit: cover;
      border-radius: 50%;
      border: 3px solid $primary-color;
    }

    .storeInfo {
      .storeName {
        font-size: 1.75rem;
        color: $primary-color;
        margin: 0;
      }
      .location {
        font-size: 1.1rem;
        color: $text-color;
        margin-top: 4px;
      }
    }
  }

  .details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;

    .metric {
      display: flex;
      flex: 0 0 48%;
      background: #fff;
      padding: 14px;
      margin-bottom: 12px;
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

      .label {
        font-size: 0.95rem;
        font-weight: bold;
        color: $secondary-color;
      }

      .value {
        font-size: 1.1rem;
        color: $text-color;
        text-align: center;
        margin-left: 4px;
      }
    }
  }

  .totalSales {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    padding-top: 12px;
    border-top: 1px solid $seller-border;

    .totalLabel {
      font-size: 1.1rem;
      font-weight: bold;
      color: #ff9100; // Gold tone for the label
    }

    .totalValue {
      font-size: 1.8rem;
      font-weight: bold;
      color: #2da805; // A vibrant green for the total value
    }
  }
}