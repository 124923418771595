.sellerSearchWrapper{
    display: flex;
    padding: 0 5px;
    justify-content: space-around;
    overflow: hidden;
}

@media (max-width: 600px) {
    .sellerSearchWrapper {
      flex-direction: column;
      align-items: center;
    //   overflow-y: auto;           // allow vertical scrolling
    //   overscroll-behavior: contain;/* prevents scroll events from bubbling to parent scroll containers */
    //   max-height: 100vh; 
    }
  }