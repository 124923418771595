.wrapper {
    padding: 5px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    min-height: 60px;
    margin: 5px 0 8px 0;
    z-index: 17;
    background: linear-gradient(rgb(62, 62, 71), rgb(47, 48, 51));
    overflow: hidden;

    .stats {
        display: flex;
        justify-content: space-between;
        margin-left: -3px;
    }
    .statWrapper {
        display: flex;
        flex-direction: column;
        margin: 0 5px;

        p {
            max-width: 60px;
        }
    }
    h3 {
        font-size: 30px;
        color: rgb(228, 228, 228);
        font-weight: normal;
    }
    a {
        color: white;
        font-size: 14px;
    }

    h4 {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: rgb(235, 230, 230);
        font-weight: normal;

        p {
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            color: rgb(240, 89, 35);
            font-size: 13px;
            margin: 3px;
            font-weight: normal;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}