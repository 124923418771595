.wrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding-top: 90px;
    z-index: 100;
}

.menuWrapper {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    width: 180px;
    box-shadow: 1px 2px 8px 1px rgb(0, 0, 0);
    margin-left: 8px;
    z-index: 100;

    a {
        text-decoration: none;
        color: rgb(10, 10, 10);
        font-size: 24px;
        margin: 5px;
        &:hover {
            color: #b64e29;
        }
    }

}