.carousel {
    display: flex;
    // justify-content: center;
    overflow-x: scroll;
    overflow-y: hidden;
    // -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    // scrollbar-width: none; /* Hide scrollbar in Firefox */
    // white-space: nowrap;
    padding: 8px;
  }
  
  .row {
    padding: 10px;
    margin-right: 10px;
    font-size: 18px;
    cursor: pointer;
    transition: transform 0.3s, color 0.3s;
    // border: 2px solid green;
    // background-color: rgb(4, 163, 4);
    color: white;
    border-radius: 10%;
  }
  
  .row:hover {
    transform: scale(1.1);
  }
  
  

  .oneDay {
    border: 2px solid rgb(4, 163, 4) !important;
}

.twoDay {
    border: 2px solid rgb(2, 187, 201) !important;
}

.threeFiveDay {
  border: 2px solid rgb(32, 102, 253) !important;
}

.sixSevenDay {
  border: 2px solid rgb(76, 1, 197) !important;
}

.selectedRow {
  color: #fff; /* Change the color for the selected row */
  background-color: #b64e29 !important; /* Change the background color for the selected row */
  // border: 2px solid #b64e29;
  border-radius: 5px;
}

.isHot {
  border-bottom: 2px solid orange !important;
}