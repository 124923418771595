.partInfoWrapper {
    display: flex;
    height: fit-content;
    height: 130px;
    width: 290px;
    padding: 2px;
    margin: 2px;
    background-color: rgb(55, 55, 55);

    .partNumbers {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: nowrap;

        p {
            color: white;
            font-size: 16px;
            margin: 0px 4px;
        }
    }

    .classificationInfo {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        .icon {
            height: 20px;
            margin: 2px;
        }
    }

    .literDetails{
        display: flex;
        justify-content: space-evenly;
    }

    .literDetail{
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 600;
            height: 25px;
            width: 25px;

            margin: 2px;
            background-color: rgb(92, 92, 92);
            color: #e46a3e;
            border-radius: 5px;
            margin-right: 5px;
        }
        .value{
            color: white;
            font-size: 16px;

        }
    }



    h2 {
        color: rgba(255, 255, 255, 0.815);
        font-size: 14px;
        text-align: center;
        padding: 0;
    }

    .optionsImg:hover {
        background-color: #b64e29;
        cursor: pointer;
    }

    .optionsImg {
        width: 7px;
        background-color: white;
    }

    .partsImage {
        height: 25px;
    }

    .partsImage2 {
        height: 25px;
    }



    .partInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2px 6px;
        flex: 50%;


    }

    .mainImage {
        height: 100%;
        max-width: 49%;
        flex: 50%;
    }

}