.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    background-color: rgb(55, 55, 55);
    display: flex;
    align-items: center;
  }
  
  .searchInput {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    font-size: 18px;
    width: 180px;
  }
  
  .searchButton {
    padding: 10px 15px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    margin-right: 5px;
  }
  
  .searchButton:hover {
    background-color: #45a049;
  }
  
  .cancelButton {
    padding: 10px 15px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .cancelButton:hover {
    background-color: #d32f2f;
  }