$primary-color: #b64e29;
$secondary-color: #9b3814;
$border-color: #e0e0e0;
$background: linear-gradient(to top, rgb(79, 82, 88), rgb(57, 57, 65) );
$text-color: #333;
$font-family: 'Lato', sans-serif;
$label-font: 'Lato', sans-serif;
$input-border: #ccc;
$box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

.modalContainer {
  font-family: $font-family;
  width: 350px;
  margin: 0 auto 10px auto;
  padding: 20px 30px;
  background: $background;
  border: 1px solid $border-color;
  border-radius: 10px;
  box-shadow: $box-shadow;
  color: $text-color;
}

.searchModal {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .topRow {
    display: flex;
    gap: 15px;

    input.usernameInput {
      flex: 1;
      padding: 10px 14px;
      border: 1px solid $input-border;
      border-radius: 4px;
      font-size: 1.25rem;
      transition: border 0.2s ease;
      max-width: 150px;
      text-align: center;
      
      &:focus {
        border-color: $primary-color;
        outline: none;
      }
    }

    button.loadButton {
      padding: 10px 20px;
      background: $primary-color;
      color: #fff;
      border: none;
      border-radius: 4px;
      font-size: 1rem;
      cursor: pointer;
      transition: background-color 0.2s ease;
      
      &:hover:not(:disabled) {
        background: $secondary-color;
      }

      &:disabled {
        background: lighten($primary-color, 20%);
        cursor: default;
      }
    }
  }

  .slidersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .sliderGroup {
      flex: 1 1 45%;
      display: flex;
      flex-direction: column;
      
      label {
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 6px;
        color: white;
        font-family: $label-font;
      }
      
      input[type="range"] {
        appearance: none;
        width: 100%;
        height: 6px;
        border-radius: 3px;
        background: #ddd;
        outline: none;
        
        &::-webkit-slider-thumb {
          appearance: none;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: $primary-color;
          cursor: pointer;
        //   border: 2px solid $background;
          margin-top: 0px;
        }
        
        &::-moz-range-thumb {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: $primary-color;
          cursor: pointer;
        //   border: 2px solid $background;
        }
      }
    }
  }

  .textInputsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .inputGroup {
      display: flex;
      flex-direction: column;

      label {
        font-size: 0.9rem;
        font-weight: 600;
        margin-bottom: 6px;
        color: white;
        font-family: $label-font;
      }

      input {
        padding: 10px 12px;
        font-size: 1rem;
        border: 1px solid $input-border;
        border-radius: 4px;
        transition: border 0.2s ease;
        
        &:focus {
          border-color: $primary-color;
          outline: none;
        }
      }
    }
  }
  button.filtersButton {
    padding: 10px 20px;
    background: $primary-color;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
    
    &:hover:not(:disabled) {
      background: $secondary-color;
    }

    &:disabled {
      background: lighten($primary-color, 20%);
      cursor: default;
    }
  }
}