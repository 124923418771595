$primary-color: #d36d38;
$secondary-color: #b2542a;
$background: #f9f9f9;
$text-color: #080808;
$card-bg: #ffffff;
$border-color: #ccc;
$font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
  "Lucida Sans Unicode", Geneva, Verdana, sans-serif;

.listingTableWrapper {
  width: 100%;
  max-height: calc(100vh - 80px);
  padding: 5px auto;
  margin: 5px;
  overflow-y: auto; // Allow vertical scrolling so you can scroll back up
  position: relative;
  z-index: 1;
}

.listingGroupsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; // Space between groups; adjust as needed.
  justify-content: center; // or center, if desired.
  width: 100%;
}

.listingGroup {
  position: relative;
  width: 160px; // Adjust width as needed.
  transition: background-color 2s ease; // <-- New transition property
  scroll-margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  background: #fff;
  overflow: hidden;
  padding: 4px;
  cursor: pointer;
}
.expanded {
  flex: 1 1 100%;
  width: 100%;
  // transition: width 0.3s ease, flex 0.3s ease;
  background-color: #f8854f;
}

.listingCount {
  position: absolute;
  top: 5px;      // adjust as needed
  left: 10px;    // adjust as needed
  padding: 2px;
  font-size: 1.2rem;
  margin: 0;     // remove margin
  text-align: start;
}

.listingImage {
  width: 80px;
  height: 80px;
  object-fit: scale-down;
  border-radius: 4px;
  margin-bottom: 5px;
}

.listingTitle {
  font-size: 1rem;
  margin: 5px 0;
  text-align: center;
}

.listingPrice {
  font-size: 1rem;
  margin: 5px 0;
}

.listingGroupItems {
  display: flex;
  gap: 4px;
  padding: 10px;
  width: 100%;
  overflow-x: auto;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 160px !important;
  max-width: 200px !important;
  max-height: 300px;
  background: $card-bg;
  padding: 12px;
  border: 1px solid $border-color;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  text-align: center;
  align-items: center;

  .image {
    height: 120px;
    width: 120px;
    object-fit: scale-down;
    border-radius: 4px;
    margin-bottom: 8px;
    align-items: center;
  }

  .title {
    font-size: 0.8rem;
    margin: 4px 0;
    color: $text-color;
  }

  .text {
    font-size: 0.9rem;
    margin: 2px 0;
    color: $text-color;
    font-family: $font-family;
  }

  &:hover {
    border: 1px solid $primary-color;
    cursor: pointer;
  }
}
