.outterWrap {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    overflow-y: hidden;
    cursor: auto;

    .innerWrap {
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.50);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 10px;
        overflow: hidden;
        background-color: rgb(55, 55, 55);
        width: 200px;
        height: fit-content;
        border-radius: 10px;

        p:hover {
            background-color: #a05337;
            cursor: pointer;
        }
        p {
            width: 100%;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            border-radius: 5px;
            color: rgb(233, 206, 206);
            text-align: center;
            background-color: #b64e29;
            font-size: 16px;
            padding: 8px 8px;
            margin: 5px 0px;
        }

        select {
            font-size: 14px;
            max-width: 90%;
            margin-top: 5px;
        }
    }
}