.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 20px 10px 20px 10px;

    h3 {
        font-size: 28px;
    }
}

.rowWrapper {
    display: flex;
    min-width: 200px;
    width: 100%;
    flex: 1 1 300px;

    &:nth-child(even) {
        background-color: rgb(255, 255, 255);
    }

    &:nth-child(odd) {
        background-color: rgb(248, 248, 248);
    }

    padding: 4px;
    border-top: 1px dashed rgb(78, 78, 78);
    border-left: 1px dashed rgb(78, 78, 78);
}

.listWrapper {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    border-bottom: 1px dashed rgb(78, 78, 78);
}


.filterWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
    justify-self: flex-start;
    

    input {
        font-size: 20px;
        align-items: center;
    }

    h4 {
        margin: 0 3px 0 20px;
        font-size: 20px;
    }

    span {
        margin: 0 5px;
        cursor: pointer;
        font-size: 18px;
    }
}