.wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 25px;

    main {
        max-width: 375px;
    }

    .listWrapper {
        height: 490px;
        overflow: auto;
    }
}