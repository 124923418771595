.w3-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;

  .arrow {
    color: #b64e29;
    padding: 4px;
    font-size: 25px;
  }
  p {
    color: white;
  }
  h2 {
    color: white;
    margin-bottom: 3px;
  }

  .partWrapper {
    padding: 5px 0px 5px 5px;
    background-color: rgb(55, 55, 55);
  }
}

.partInfoWrapper {
  display: flex;
  height: fit-content;
  min-height: 130px;
  width: 290px;
  padding: 2px;
  margin: 2px;
  background-color: rgb(55, 55, 55);

  .partNumbers {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;

    p {
      color: white;
      font-size: 16px;
      margin: 0px 4px;
    }
  }

  // .classificationInfo {
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 5px;
  //     .icon {
  //         height: 20px;
  //         margin: 2px;
  //     }
  // }

  h2 {
    color: rgba(255, 255, 255, 0.815);
    font-size: 14px;
    text-align: center;
    padding: 0;
  }

  .optionsImg:hover {
    background-color: #b64e29;
    cursor: pointer;
  }

  .optionsImg {
    width: 7px;
    background-color: white;
  }

  .partsImage {
    height: 25px;
  }

  .partsImage2 {
    height: 25px;
  }

  .partInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2px 6px;
    flex: 50%;

    > p {
      color: orange;
      text-align: center;
      margin: 3px;
      font-size: 10px;
    }
  }

  .mainImage {
    height: 100%;
    max-width: 49%;
    flex: 50%;
  }
}

// Modal

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(55, 55, 55);
  border: 1px solid #ccc;
  padding: 15px;
  z-index: 1000;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    transition: all 0.1s ease;
    cursor: pointer;
    color: white;
    margin: 8px;
  }
  p:hover {
    transform: scale(1.1) translateZ(0); 
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  /* Other styles for the close button */
}
