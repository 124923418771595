.wrapper {
    position: absolute;
    left: 100%;
    margin-left: 5px;
    border: 1px solid black;
    margin-top: -2px;
    background: linear-gradient(to top, rgb(80, 80, 90), rgb(48, 50, 53));
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.50);
    padding: 14px 10px 10px 10px;

    input[type=text] {
        font-size: 22px;
        margin-bottom: 10px;
        text-align: center;
    }
    div {
        display: flex;
        justify-content: space-around;
    }
    input[type=button], input[type=submit] {
        font-size: 20px;
    }
}

@media(max-width: 500px){
    .wrapper{
        left: calc(50% - 100px);
        top: calc(50% - 40px);
    }
    
}