.wrapper {
    // position: absolute;
    width: 375px;
    height: fit-content;
    background: linear-gradient(to top, rgb(62, 62, 71), rgb(41, 42, 44));
    padding: 15px;

    h2 {
        color: rgb(238, 238, 238);
    }

    input {
        line-height: 30px;
        font-size: 20px;
        margin:  5px;
        width: 150px;
        text-align: center;
    }

    label {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: rgb(238, 238, 238); 
        input {
            width: 20px;
            height: 20px;
            margin: 0 5px 0 0;
        }
    }

    .buttonWrapper{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 150px;
        input {
            flex: 1 1 100%;
            line-height: 30px;
            font-size: 20px;
            
        }
        button {
            width: 60px;
            height: 30px;
            font-size: 18px;
            margin: 5px;
            cursor: pointer;
        }
    }
}