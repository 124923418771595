.wrapper {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    .navLink:hover{
        color: rgb(207, 135, 0)
    }
    .navLink {
        width: 100%;
        text-align: center;
        font-size: 18px;
        color: rgb(243, 159, 2);
        text-decoration: none;
    }

    .researchPullWrapper {
        display: flex;
        margin-top: 5px;

        .researchToggle {
            width: 160px;
            border: black solid 1px;
            border-radius: 2px;
            margin: 2px;
            text-align: center;
            font-size: 18px;
            padding: 2px;
            cursor: pointer;
        }


        .selected {
            box-shadow: 0px 0px 2px 2px #b64e29;;
            background-color: #fd9e7b;
        }

    }
}